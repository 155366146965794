import {Collections, TicketSubCollections} from '@ozark/functions/src/constants';
import {TicketComment, TicketHistoryRecord} from '@ozark/functions/src/documents';

import {useEffect, useState} from 'react';
import {Firebase} from '../../../firebase';
import {TicketWidthHistoryAndComments} from '../types';

export function useTicketsExport(dateFilter: {year: number; month: number} | undefined) {
  const [tickets, setTickets] = useState<TicketWidthHistoryAndComments[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dateFilter === undefined) {
      return;
    }
    setTickets([]);
    setLoading(true);
    const startDate = new Date(dateFilter.year, dateFilter.month, 1);
    const endDate = new Date(dateFilter.year, dateFilter.month + 1, 1);
    const fetchedTickets: TicketWidthHistoryAndComments[] = [];

    Firebase.firestore
      .collection(Collections.tickets)
      .where('createdAt', '>=', startDate)
      .where('createdAt', '<', endDate)
      .orderBy('createdAt', 'asc')
      .get()
      .then(async snapshot => {
        for (const doc of snapshot.docs) {
          const ticketData = doc.data();
          const ticketHistorySnap = await doc.ref
            .collection(TicketSubCollections.history)
            .orderBy('createdAt', 'desc')
            .get();

          const commentsSnap = await doc.ref
            .collection(Collections.comments)
            .orderBy('createdAt', 'desc')
            .get();

          const commentsInternalSnap = await doc.ref
            .collection(Collections.commentsInternal)
            .orderBy('createdAt', 'desc')
            .get();

          const history = ticketHistorySnap.docs.map(hDoc => hDoc.data() as TicketHistoryRecord);
          const comments = commentsSnap.docs.map(hDoc => hDoc.data() as TicketComment);
          const commentsInternal = commentsInternalSnap.docs.map(
            hDoc => hDoc.data() as TicketComment
          );
          fetchedTickets.push({id: doc.id, history, comments, commentsInternal, ...ticketData});
        }
      })
      .finally(() => {
        setTickets(fetchedTickets);
        setLoading(false);
      });
  }, [dateFilter]);

  return {tickets, loading};
}
