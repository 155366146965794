import {Badge} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useTicketsMy} from '../hooks/useTicketsMy';
import {StatusFolderProps} from '../types';

export const TicketsListFolderMy = ({folder, isSelected}: StatusFolderProps) => {
  const {counter} = folder.hasCounter
    ? useTicketsMy(folder.statusSet, folder.filterKey)
    : {counter: 0};
  const Icon = folder.icon;

  return (
    <ListItemButton selected={isSelected} sx={{pl: folder.indent ? 4 : 2}}>
      {folder.hasCounter && (
        <ListItemIcon>
          <Badge max={999} badgeContent={counter} color={isSelected ? 'primary' : 'default'}>
            {Icon && <Icon />}
          </Badge>
        </ListItemIcon>
      )}
      {!folder.hasCounter && <ListItemIcon>{Icon && <Icon />}</ListItemIcon>}
      <ListItemText
        primary={folder.folderName}
        primaryTypographyProps={{
          fontWeight: isSelected ? '500' : 'initial',
          color: isSelected ? 'black' : 'initial',
          fontStyle: folder.italic ? 'italic' : 'initial',
        }}
      />
    </ListItemButton>
  );
};
