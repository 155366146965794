import {FieldValue, TicketHistoryRecord, UniversalTimestamp} from '../../..';
import {TicketWidthHistoryAndComments} from '../types';

const formatTimestamp = (timestamp?: UniversalTimestamp | FieldValue) =>
  timestamp && 'seconds' in timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : '';

export function transformTicketsForTable(
  response: TicketWidthHistoryAndComments[]
): Record<string, string | undefined>[] {
  return response.map(ticket => {
    const createdDate = formatTimestamp(ticket?.createdAt);
    const categoryName = ticket.category?.category?.name || '';
    const assigneeName = ticket.assignee?.erpUser?.name || '';
    const creatorName = ticket.author?.name || '';
    const labels = ticket.labels?.join(', ') || '';
    const slaBreached = ticket.slaBreachedAt ? 'Y' : 'N';

    const lastInternalCommentAuthor = ticket.lastInternalCommentInfo?.author || '';
    const lastInternalCommentDate = formatTimestamp(ticket.lastInternalCommentInfo?.createdAt);
    const lastPublicCommentAuthor = ticket.lastPublicCommentInfo?.author || '';
    const lastPublicCommentDate = formatTimestamp(ticket.lastPublicCommentInfo?.createdAt);

    const ticketHistory = ticket.history
      ?.map((h: TicketHistoryRecord) => `${h.title} (${formatTimestamp(h.createdAt)})`)
      .join(',\n');

    return {
      id: ticket.id,
      created: createdDate,
      category: categoryName,
      assignee: assigneeName,
      creator: creatorName,
      viewableBy: ticket.viewableBy,
      priority: ticket.priority,
      labels,
      subject: ticket.subject,
      description: ticket.description?.replace(/<[^>]*>/g, ''),
      slaBreached,
      lastInternalComment: lastInternalCommentAuthor
        ? `${lastInternalCommentAuthor} (${lastInternalCommentDate})`
        : '',
      lastPublicComment: lastPublicCommentAuthor
        ? `${lastPublicCommentAuthor} (${lastPublicCommentDate})`
        : '',
      ticketHistory,
    };
  });
}
